import { AddCircleOutline, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useGetDocument } from '../hooks/useGetDocument';
import { useCallback, useEffect, useState } from 'react';
import { CustomCheckbox } from 'components/CustomCheckbox';
import { useMutation } from 'react-query';
import { POST_HAS_SCHOLARSHIP } from 'utils/api';
import { handleAlerts } from 'utils';

export const SectionHeader = ({
  title,
  hasEditPermission,
  registrationId,
  setDialogs,
  hasScolarship,
  studentId,
  group,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [scolarship, setScolarship] = useState(false);
  const { getBoletaPDF } = useGetDocument(registrationId, title);
  const { successMessage, errorMessage } = handleAlerts();

  const noGrades = group?.subjects?.every(
    (subject) => subject.calificacion === null
  );

  const handleAddSubject = useCallback((term) => {
    setDialogs((dialogs) => ({ ...dialogs, openCreate: true, term }));
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setScolarship(hasScolarship);
  }, [hasScolarship]);

  const postHasScholarhip = useMutation({
    mutationFn: (becado) => {
      return POST_HAS_SCHOLARSHIP(studentId, becado, title);
    },
    onSuccess: () => {
      successMessage('Se ha actualizado el estado de becado');
    },
    onError: (error) => {
      errorMessage('No se ha podido actualizar el estado de becado');
      console.error('Error', error);
    },
  });

  const termYear = Number(title.split('-')[0]);

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      marginBottom='16px'>
      <Typography variant='subtitle2' sx={{ color: 'secondary.main' }}>
        Periodo {title}
      </Typography>
      <Box display='flex' columnGap='16px' alignItems='center'>
        <CustomCheckbox
          label='Becado'
          disabled={termYear < 2020}
          checked={scolarship}
          onChange={(_event, checked) => {
            setScolarship(checked);
            postHasScholarhip.mutate(checked);
          }}
        />
        <Button
          variant='text'
          sx={{ color: 'info.darker' }}
          startIcon={<Download />}
          onClick={handleClick}
          disabled={noGrades}>
          Boleta EN
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem>
            <Button
              onClick={() => {
                getBoletaPDF('en', true);
                handleClose();
              }}>
              Firmada
            </Button>
          </MenuItem>
          <MenuItem>
            <Button
              onClick={() => {
                getBoletaPDF('en', false);
                handleClose();
              }}>
              Sin firma
            </Button>
          </MenuItem>
        </Menu>

        <Button
          variant='text'
          sx={{ color: 'primary.darker' }}
          startIcon={<Download />}
          onClick={() => {
            getBoletaPDF('es');
          }}
          disabled={noGrades}>
          Boleta ES
        </Button>
        {hasEditPermission && (
          <Tooltip title='Añadir materia'>
            <Button
              id='add-subject-button'
              onClick={() => handleAddSubject(title)}>
              <AddCircleOutline />
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool,
  registrationId: PropTypes.string.isRequired,
  setDialogs: PropTypes.func.isRequired,
};
