import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect';
import PropTypes from 'prop-types';

export const PrintOption = ({
  title,
  onClick,
  radioOptions,
  signedData,
  disabled,
}) => {
  return (
    <MenuItem key={1} sx={{ padding: 0 }} disabled={disabled}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100% '
        columnGap='16px'
        padding='0 0 0 16px'
        minHeight='42px'>
        <Box
          minWidth='130px'
          width={radioOptions ? 'auto' : '100%'}
          onClick={onClick}
          sx={{ cursor: 'pointer' }}>
          <p>{title}</p>
        </Box>
        {radioOptions && (
          <FormControl style={{ width: 'max-content' }}>
            <RadioGroup row value={radioOptions.value}>
              {radioOptions.options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      onChange={(event) =>
                        radioOptions.setValue(event.target.value)
                      }
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {signedData?.showSelect && (
          <CustomSelect
            options={[
              { value: true, label: 'Firmado' },
              { value: false, label: 'Sin firma' },
            ]}
            value={signedData.isSigned}
            handleChange={signedData.handleChange}
            placeholder='Seleccione una opción'
          />
        )}
      </Box>
    </MenuItem>
  );
};

PrintOption.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  radioOptions: PropTypes.object,
};
